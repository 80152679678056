import moment from "moment";
import {
  DispatchPreview,
  Driver,
} from "@api/graphql/generated/generated-types";
import { DispatchCandidateSortingType } from "../types";
import { DispatchCandidateView } from "@src/common/components/Widgets/DispatchCandidatesWidget/DispatchCandidatesWidget";
import { sortDispatchPreviews } from "./sortDispatchPreviews";
import { DriverMapItem } from "@src/common/components/Widgets/DriverMapWidget";
import { displayDispatchPreviewTag } from "./dispatchPreviewTagDisplay";
import { displayVehicleType } from "@src/drivers/utils/displayVehicleType";
import { isDateStringToday } from "@src/common/lib/DateUtils";
import { metersToMiles, roundToOneDecimal } from "@src/common/lib/NumberUtils";
import { PlusIcon } from "@heroicons/react/24/outline";
import StatusDisplay from "@src/common/components/StatusDisplay";

// This function formats data from the API to be used in the driver list in the UI
// It takes in the API data and returns an array of drivers
export function makeDispatchCandidateViews(
  apiData: any,
  dispatchCandidateSortingType?: DispatchCandidateSortingType
): DispatchCandidateView[] {
  const dispatchPreviews: DispatchPreview[] = sortDispatchPreviews(
    apiData.dispatchPreviews,
    dispatchCandidateSortingType
  );
  const candidates: DispatchCandidateView[] = dispatchPreviews.map(
    (dispatchPreview: DispatchPreview) => {
      const driver: Driver = dispatchPreview.driver;

      let remainingWorkloadInSeconds;
      if (dispatchPreview.remainingWorkloadInSeconds === 0) {
        remainingWorkloadInSeconds = 0;
      } else {
        remainingWorkloadInSeconds =
          dispatchPreview.remainingWorkloadInSeconds || undefined;
      }

      const isShipmentToday = isDateStringToday(dispatchPreview.shipmentDate);

      let distanceInMeters = dispatchPreview.distanceInMeters || undefined;
      if (!isShipmentToday) {
        distanceInMeters = undefined;
      }

      return {
        id: driver.id || "",
        title: driver.firstName || "",
        fullName: `${driver.firstName} ${driver.lastName}`,
        subText: makeSubtext(dispatchPreview, true, false),
        image: driver.photoUrl || "",
        badgeText:
          (dispatchPreview.tags.length > 0 &&
            dispatchPreview.driver.isActive &&
            displayDispatchPreviewTag(dispatchPreview.tags[0])) ||
          undefined,
        distanceInMeters: distanceInMeters,
        distanceAdditionalInMeters:
          dispatchPreview.distanceAdditionalInMeters || undefined,
        vehicleType: displayVehicleType(driver.vehicle?.transportationType),
        remainingWorkloadInSeconds: remainingWorkloadInSeconds,
        phone: driver.phone,
        isActive: driver.isActive,
        driver: driver,
        dispatchPreview: dispatchPreview,
        workType: driver.workType,
        __typename: "DispatchCandidateView",
      };
    }
  );
  return candidates;
}

export function makeDriverMapItems(apiData: any): DriverMapItem[] {
  return apiData.dispatchPreviews
    .filter(
      (dispatchPreview: DispatchPreview) => dispatchPreview.driver.isActive
    )
    .map((dispatchPreview: DispatchPreview) => {
      const durationToShipmenText = moment
        .duration(dispatchPreview.durationInSeconds, "seconds")
        .humanize();
      return {
        id: dispatchPreview.driver.id || "",
        title: dispatchPreview.driver.firstName || "",
        subText:
          `${durationToShipmenText} - ${dispatchPreview.numCurrentTasks} tasks` ||
          "",
        image: dispatchPreview.driver.photoUrl || "",
        coordinate: dispatchPreview.driver.lastReportedLocation?.lngLat,
        isActive: dispatchPreview.driver.isActive,
      };
    });
}

export function makeSubtext(
  dispatchPreview: DispatchPreview,
  showDriverInfo?: boolean,
  showDistanceOnly?: boolean
) {
  let durationToShipmenText: string | undefined;
  if (
    dispatchPreview.distanceInMeters === null ||
    dispatchPreview.distanceInMeters === undefined
  ) {
    durationToShipmenText = undefined;
  } else if (dispatchPreview.distanceInMeters >= 1600) {
    durationToShipmenText = `${roundToOneDecimal(
      metersToMiles(dispatchPreview.distanceInMeters)
    )} mi`;
  } else {
    durationToShipmenText = "Nearby";
  }

  let tasksInfoText = `${dispatchPreview.numCurrentTasks}`;
  if (dispatchPreview.numCurrentTasks === 0) {
    tasksInfoText = "Free";
  }

  let distanceAdditionalInMeters =
    dispatchPreview.distanceAdditionalInMeters || undefined;
  const isShipmentToday = isDateStringToday(dispatchPreview.shipmentDate);

  const distanceAdditionalText: string = distanceAdditionalInMeters
    ? `${roundToOneDecimal(metersToMiles(distanceAdditionalInMeters))} mi`
    : "0 mi";

  if (isShipmentToday) {
    if (showDistanceOnly) {
      return (
        <div className="flex items-center">
          <div>
            <PlusIcon className="h-4 w-4" />
          </div>
          {distanceAdditionalText}
        </div>
      );
    }
    return (
      <div className="grid grid-cols-3">
        {durationToShipmenText && <div>{durationToShipmenText}</div>}
        {showDriverInfo && (
          <div className="flex items-center">
            <StatusDisplay value={dispatchPreview.numCurrentTasks} />
          </div>
        )}
        <div className="flex justify-end items-center">
          <div>
            <PlusIcon className="h-4 w-4" />
          </div>
          <div>{distanceAdditionalText}</div>
        </div>
      </div>
    );
  }

  return `${tasksInfoText}`;
}

export function driversToDispatchCandidateViews(
  drivers: Driver[]
): DispatchCandidateView[] {
  return drivers.map((driver: Driver) => {
    const tasksLength = driver.tasks?.length || 0;
    return {
      id: driver.id || "",
      title: driver.firstName || "",
      fullName: `${driver.firstName} ${driver.lastName}`,
      subText: (
        <div className="flex items-center">
          <StatusDisplay value={tasksLength} />
        </div>
      ),
      image: driver.photoUrl || "",
      badgeText: "",
      distanceInMeters: undefined,
      distanceAdditionalInMeters: undefined,
      vehicleType: displayVehicleType(driver.vehicle?.transportationType),
      remainingWorkloadInSeconds: undefined,
      phone: driver.phone,
      isActive: driver.isActive,
      driver: driver,
      dispatchPreview: undefined,
      workType: driver.workType,
      __typename: "DispatchCandidateView",
    };
  });
}

import { useCallback } from "react";
import { Driver } from "@api/graphql/generated/generated-types";

interface UseDispatchByDriverIdProps {}

interface UseDispatchByDriverIdReturn {
  onClickAddOrder: () => void;
}

export const useDispatchByDriverId =
  ({}: UseDispatchByDriverIdProps): UseDispatchByDriverIdReturn => {
    const onClickAddOrder = useCallback(() => {
      console.log("onClickAddOrder");
    }, []);
    return { onClickAddOrder };
  };

import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import {
  DispatchPreview,
  DispatchPreviewReadyStatus,
  Driver,
  Stop,
  useDispatchPreviewsByDriverIdQuery,
} from "@api/graphql/generated/generated-types";
import { useAuth } from "@src/common/hooks/useAuth";
import DispatchPreviewItem from "./DispatchPreviewItem";
import { useEffect, useState } from "react";
import { useErrorToast } from "@src/common/hooks/useErrorToast";
import {
  DispatchCandidateSortingType,
  ShipmentRow,
} from "@src/shipments/types";
import { sortDispatchPreviews } from "@src/shipments/utils/sortDispatchPreviews";

interface AddOrderListProps {
  onBack: () => void;
  driver: Driver;
  targetDate: string;
  onSelectPreview: (preview: DispatchPreview) => void;
  setHoveredStopsFromWidget: (
    hoveredStopsFromWidget: Stop[] | undefined
  ) => void;
  selectedRow: ShipmentRow | undefined;
}

interface OrderGroup {
  title: string;
  status: DispatchPreviewReadyStatus;
  orders: DispatchPreview[];
  loading: boolean;
  error?: string;
}

function AddOrderList({
  onBack,
  driver,
  targetDate,
  onSelectPreview,
  setHoveredStopsFromWidget,
  selectedRow,
}: AddOrderListProps) {
  const { courierId } = useAuth();
  const showError = useErrorToast();
  const [currentLoadingStatus, setCurrentLoadingStatus] =
    useState<DispatchPreviewReadyStatus>(DispatchPreviewReadyStatus.ReadyNow);

  const [orderGroups, setOrderGroups] = useState<OrderGroup[]>([
    {
      title: "Ready Now",
      status: DispatchPreviewReadyStatus.ReadyNow,
      orders: [],
      loading: true,
    },
    {
      title: "Ready Soon",
      status: DispatchPreviewReadyStatus.ReadySoon,
      orders: [],
      loading: false,
    },
    {
      title: "Ready Later",
      status: DispatchPreviewReadyStatus.ReadyLater,
      orders: [],
      loading: false,
    },
  ]);

  const { data, loading, error } = useDispatchPreviewsByDriverIdQuery({
    variables: {
      courierId: courierId!,
      driverId: driver.id,
      targetDate: targetDate,
      readyStatus: currentLoadingStatus,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading) {
      // Update current group with data
      setOrderGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (group.status === currentLoadingStatus) {
            const dispatchPreviews = data?.dispatchPreviewsByDriverId || [];
            const sortedDispatchPreviews = sortDispatchPreviews(
              dispatchPreviews as DispatchPreview[],
              DispatchCandidateSortingType.LEAST_DEVIATION
            );
            return {
              ...group,
              loading: false,
              orders: sortedDispatchPreviews as DispatchPreview[],
              error: error?.message,
            };
          }
          return group;
        })
      );

      // Move to next status if available
      if (!error) {
        if (currentLoadingStatus === DispatchPreviewReadyStatus.ReadyNow) {
          setCurrentLoadingStatus(DispatchPreviewReadyStatus.ReadySoon);
          setOrderGroups((prevGroups) =>
            prevGroups.map((group) =>
              group.status === DispatchPreviewReadyStatus.ReadySoon
                ? { ...group, loading: true }
                : group
            )
          );
        } else if (
          currentLoadingStatus === DispatchPreviewReadyStatus.ReadySoon
        ) {
          setCurrentLoadingStatus(DispatchPreviewReadyStatus.ReadyLater);
          setOrderGroups((prevGroups) =>
            prevGroups.map((group) =>
              group.status === DispatchPreviewReadyStatus.ReadyLater
                ? { ...group, loading: true }
                : group
            )
          );
        }
      }
    }
  }, [loading, data, error, currentLoadingStatus]); // Removed sortingType from dependencies

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  return (
    <div className="flex flex-col h-full bg-white shadow-sm border rounded-tl-2xl">
      <div className="flex flex-col px-4 pt-4 pb-2 border-b">
        <div className="flex justify-between items-center">
          {selectedRow && (
            <span
              onClick={onBack}
              className="hover:cursor-pointer hover:underline text-primary-500 flex items-center"
            >
              <ArrowLeftIcon className="h-4 w-4 inline-block mr-1.5" />
              Back
            </span>
          )}
        </div>
        <div className="text-center pb-2 mt-2">
          <h2 className="text-sm font-semibold text-gray-800">
            Add Order to Route
          </h2>
          <p className="text-gray-500 mt-1 text-base">{`${driver.firstName} ${driver.lastName}`}</p>
        </div>
      </div>

      <div className="flex-1 overflow-auto px-4 pt-2">
        {!loading &&
        orderGroups.every(
          (group) => !group.loading && group.orders.length === 0
        ) ? (
          <div className="flex flex-col items-center justify-center h-full text-gray-500">
            <p className="text-lg">No orders available</p>
          </div>
        ) : (
          orderGroups.map((group) => {
            if (!group.loading && group.orders.length === 0) return null;

            return (
              <div key={group.status} className="py-4 first:pt-4">
                <h3 className="text-lg font-semibold text-gray-700 mb-3 px-1">
                  {group.title}
                </h3>
                {group.loading ? (
                  <div className="text-gray-500 text-center py-6">
                    Loading...
                  </div>
                ) : group.error ? (
                  <div className="text-red-500 text-center py-6 bg-red-50 rounded-lg">
                    {group.error}
                  </div>
                ) : (
                  <div className="space-y-2">
                    {group.orders.map((preview) => (
                      <div
                        key={preview.shipmentId}
                        onClick={() => onSelectPreview(preview)}
                        onMouseEnter={() => {
                          const stops = preview.shipment.stops;
                          setHoveredStopsFromWidget(stops as Stop[]);
                        }}
                        onMouseLeave={() =>
                          setHoveredStopsFromWidget(undefined)
                        }
                        className="border cursor-pointer rounded-lg hover:bg-gray-50 transition-colors duration-150"
                      >
                        <DispatchPreviewItem
                          preview={preview}
                          className="hover:shadow-none"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default AddOrderList;
